import FusionCharts from "fusioncharts";
FusionCharts.options.license({
    key: 'wkB3gzpI2J3A4A6B5A4C4E4E4C3G1A7C8wfhC4D1aieynD6D3B-8kdG2C9A4D6C5G5F4G2C3I3B4A5C4B1A2B4snnB14B1D8fB4A4H4H-8I-7iA7A3A3wqh1H2C1C5B-22moD2D5F1G3D2A6B5A4C7A3B4h1jjB8C4QB5jnB-8H3F1B5tgB2HA1E2A-7H-7nA2D6E1A3D3B2G2C2A5A3A5C2D2B3t==',
    creditLabel: false,
});

//The `FusionCharts.register()` API is used to register the new theme in the FusionCharts core.
FusionCharts.register('theme', {
    name: 'buddy-theme',
    theme: {
        base: {
            chart: {
                paletteColors: "#1272a4, #8cca8b, #e06e5e, #fec663, #01baef, #BC95DF, #67CDF2",
                showShadow: "0",
                showPlotBorder: "0",
                usePlotGradientColor: "0",
                showValues: "0",
                canvasBgAlpha: "0",
                bgAlpha: "100",
                showCanvasBorder: "0",
                showAlternateHGridColor: "0",
                divLineColor: "#DFDFDF",
                showXAxisLine: "0",
                yAxisNamePadding: "15",
                sYAxisNamePadding: "15",
                xAxisNamePadding: "15",
                captionPadding: "15",
                sYAxisNameFontColor: "#999",
                yAxisValuesPadding: "15",
                labelPadding: "10",
                transposeAxis: "1",
                toolTipBgColor: "#FFFFFF",
                toolTipPadding: "6",
                toolTipBorderColor: "#E1E1E1",
                toolTipBorderThickness: "1",
                toolTipBorderAlpha: "100",
                toolTipBorderRadius: "2",
                baseFontColor: "#5A5A5A",
                baseFontSize: "14",
                xAxisNameFontBold: "0",
                yAxisNameFontBold: "0",
                sYAxisNameFontBold: "0",
                xAxisNameFontSize: "15",
                yAxisNameFontSize: "15",
                sYAxisNameFontSize: "15",
                captionFontSize: "18",
                captionFontFamily: "Ubuntu",
                baseFont: "Ubuntu",

                subCaptionFontSize: "13",
                captionFontBold: "1",
                subCaptionFontBold: "0",
                subCaptionFontColor: "#999",
                valueFont: "Ubuntu",

                drawCustomLegendIcon: "1",
                legendShadow: "0",
                legendBorderAlpha: "0",
                legendBorderThickness: "0",
                legendItemFont: "Ubuntu",
                legendIconBorderThickness: "0",
                legendBgAlpha: "0",
                legendItemFontSize: "15",
                legendCaptionFontColor: "#999",
                legendCaptionFontSize: "13",
                legendCaptionFontBold: "0",
                legendScrollBgColor: "#FFF",

                crossLineAnimation: "1",
                crossLineAlpha: "100",
                crossLineColor: "#DFDFDF",
                showHoverEffect: "1",
                plotHoverEffect: "1",
                plotFillHoverAlpha: "90",
                barHoverAlpha: "90",
                bgColor: "#FFFFFF",
                borderThickness: "1",
                borderRadius: "10",
                borderAlpha: "100",
                borderColor: "#D9D9D9",
                showBorder: "1",
                labelFontColor: "#202124", // xval
                valueFontColor: "#787575",
                yAxisValueFontColor: "#787575", //yval
                yAxisNameFontColor: "#A6A6A6", // ylabel
                xAxisNameFontColor: "#A6A6A6",
                xAxisValueFontColor: "#202124",
                legendItemFontColor: "#A6A6A6",
                captionFontColor: "#202124",

            },
        },
        multiaxisline: {
            chart: {
                showLegend: "1",
                lineThickness: "2",
                allowSelection: "0",
                connectNullData: "1",
                drawAnchors: "1",
                divLineDashed: "0",
                divLineColor: "#DFDFDF",
                vDivLineColor: "#DFDFDF",
                vDivLineDashed: "0",
                yAxisNameFontSize: "13",
                drawCustomLegendIcon: "0",
                showhovereffect: "1",
                drawcrossline: "1",
            },

            axis: [{ divLineColor: "#DFDFDF", setAdaptiveYMin: "1", divLineDashed: "0" }],
        },
        sparkline: {
            chart: {
                plotFillColor: "#DFDFDF",
                anchorRadius: "4",
                highColor: "#8CCA8B", //Buddy data C1
                lowColor: "#E06E5E", //Buddy data B1
                captionPosition: "top",

                showOpenAnchor: "0 !important",
                showCloseAnchor: "0 !important",
                showOpenValue: "0 !important",
                showCloseValue: "0 !important",
                showHighLowValue: "0 !important",


                periodColor: "#F3F3F3",
                showBorder: "0",
                lineColor: "#D9D9D9"
            },
        },
        bar2d: { chart: { placeValuesInside: "0", showAlternateVGridColor: "0", yAxisValuesPadding: "10", } },
        mscombidy2d: {
            chart: {
                paletteColors: "#89BCD8, #A01A7D, #e06e5e, #fec663, #01baef, #BC95DF, #67CDF2 !important",
                drawCustomLegendIcon: "0 !important",
                lineThickness: "2", anchorRadius: "4", drawCrossLine: "1", showLegend: "1", anchorHoverEffect: "1", anchorHoverRadius: "4",
                anchorBorderHoverThickness: "1.5", anchorBgHoverColor: "#FFFFFF", legendIconBorderThickness: "1",
                maxLabelHeight: "100",
                decimals: "0", labelPadding: "23"
            },
        },
        HLinearGauge: { chart: { chartLeftMargin: "30", chartRightMargin: "30", ledGap: "0", showGaugeBorder: "0", setAdaptiveMin: "1", adjustTM: "1", placeTicksInside: "0", autoAlignTickValues: "1", minortmnumber: "0", majorTMHeight: "8", majorTMAlpha: "20" } },
        angulargauge: {
            chart: {
                setAdaptiveMin: "0", adjustTM: "1",
                tickvaluedistance: "10", placeTicksInside: "0",
                autoAlignTickValues: "1", showGaugeBorder: "0",
                minortmnumber: "0", majorTMHeight: "0",
                gaugeFillMix: "{light-0}", pivotbgcolor: "#000000",
                pivotfillmix: "0", showpivotborder: "1",
                pivotBorderColor: "#FFFFFF", showValue: "0",
                valueBelowPivot: "1",
                showTickMarks: "0", showTickValues: "0",
                showLimits: "0",
                pivotRadius: "0",
                bgColor: "#2A3143",
                showBorder: "0",
                animation: "0",
                baseFontSize: "28",
                valueFontColor: "#FFFFFF",
            },
            dials: {
                dial: [{
                    bgColor: "#2A3143", borderThickness: "0",
                    "basewidth": "0",
                    topwidth: "0",
                    borderthickness: "0",
                    valuey: "140"
                }]
            }
        },
        overlappedbar2d:
        {
            chart:
            {
                paletteColors: "#D9D9D9, #A01A7D !important",
                placeValuesInside: "0", showAlternateVGridColor: "0", showLegend: "1", legendIconSides: "4", yAxisValuesPadding: "25"
            }
        }
    }
});

FusionCharts.register('theme', {
    name: 'buddy-theme-dark',
    theme: {
        base: {
            chart: {
                paletteColors: "#1272a4, #8cca8b, #e06e5e, #fec663, #01baef, #BC95DF, #67CDF2",
                showShadow: "0",
                showPlotBorder: "0",
                usePlotGradientColor: "0",
                showValues: "0",
                canvasBgAlpha: "0",
                bgAlpha: "100",
                showCanvasBorder: "0",
                showAlternateHGridColor: "0",
                divLineColor: "#DFDFDF",
                showXAxisLine: "0",
                yAxisNamePadding: "15",
                sYAxisNamePadding: "15",
                xAxisNamePadding: "15",
                captionPadding: "15",
                sYAxisNameFontColor: "#c0c0c0",
                pYAxisNameFontColor: "#c0c0c0",
                yAxisValuesPadding: "15",
                labelPadding: "10",
                transposeAxis: "1",
                toolTipBgColor: "#FFFFFF",
                toolTipPadding: "6",
                toolTipBorderColor: "#E1E1E1",
                toolTipBorderThickness: "1",
                toolTipBorderAlpha: "100",
                toolTipBorderRadius: "2",
                baseFontColor: "#5A5A5A",
                baseFontSize: "14",
                xAxisNameFontBold: "0",
                yAxisNameFontBold: "0",
                sYAxisNameFontBold: "0",
                xAxisNameFontSize: "15",
                yAxisNameFontSize: "15",
                sYAxisNameFontSize: "15",
                captionFontSize: "18",
                captionFontFamily: "CenturyGothic, sans-serif",
                baseFont:"CenturyGothic, sans-serif",
                
                subCaptionFontSize: "13",
                captionFontBold: "1",
                subCaptionFontBold: "0",
                subCaptionFontColor: "#999",
                valueFont: "CenturyGothic, sans-serif",

                drawCustomLegendIcon: "1",
                legendShadow: "0",
                legendBorderAlpha: "0",
                legendBorderThickness: "0",
                legendItemFont: "CenturyGothic, sans-serif",
                legendIconBorderThickness: "0",
                legendBgAlpha: "0",
                legendItemFontSize: "15",
                legendCaptionFontColor: "#999",
                legendCaptionFontSize: "13",
                legendCaptionFontBold: "0",
                legendScrollBgColor: "#FFF",
                legendItemFontColor: "#c0c0c0",

                crossLineAnimation: "1",
                crossLineAlpha: "100",
                crossLineColor: "#DFDFDF",
                showHoverEffect: "1",
                plotHoverEffect: "1",
                plotFillHoverAlpha: "90",
                barHoverAlpha: "90",
                bgColor: "#2a3143",
                borderThickness: "1",
                borderColor: "#808080",
                showBorder: "1",
                labelFontColor: "#FFFFFF",
                valueFontColor: "#FFFFFF",
                yAxisValueFontColor: "#FFFFFF",
                yAxisNameFontColor: "#FFFFFF",
                xAxisNameFontColor: "#FFFFFF",
                xAxisValueFontColor: "#FFFFFF",
                
                captionFontColor: "#FFFFFF",


            },
        },
        multiaxisline: {
            chart: {
                showLegend: "1",
                lineThickness: "2",
                allowSelection: "0",
                connectNullData: "1",
                drawAnchors: "1",
                divLineDashed: "0",
                divLineColor: "#DFDFDF",
                vDivLineColor: "#DFDFDF",
                vDivLineDashed: "0",
                yAxisNameFontSize: "13",
                drawCustomLegendIcon: "0",
                showhovereffect: "1",
                drawcrossline: "1",
            },
            
            axis: [{ divLineColor: "#DFDFDF", setAdaptiveYMin: "1", divLineDashed: "0" }],
        },
        sparkline: {
            chart: {
                plotFillColor: "#DFDFDF",
                anchorRadius: "4",
                highColor: "#32cd30",
                lowColor: "#ec1555",
                captionPosition: "top",

                showOpenAnchor: "0 !important",
                showCloseAnchor: "0 !important",
                showOpenValue: "0 !important",
                showCloseValue: "0 !important",
                showHighLowValue: "0 !important",
                

                periodColor: "#F3F3F3",
                showBorder: "0",
                lineColor:"#4fadc5"
            },
        },
        bar2d: { chart: { placeValuesInside: "0", showAlternateVGridColor: "0", yAxisValuesPadding: "10", } },
        mscombidy2d: {
            chart: {
                paletteColors: "#1272a4, #A01A7D, #e06e5e, #fec663, #01baef, #BC95DF, #67CDF2 !important",
                drawCustomLegendIcon: "0 !important",
                lineThickness: "2", anchorRadius: "4", drawCrossLine: "1", showLegend: "1", anchorHoverEffect: "1", anchorHoverRadius: "4",
                anchorBorderHoverThickness: "1.5", anchorBgHoverColor: "#FFFFFF", legendIconBorderThickness: "1",
                maxLabelHeight: "100",
                decimals: "0", labelPadding: "23"
            },
        },
    }
});

export default FusionCharts;