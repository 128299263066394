import React from 'react';
import FusionCharts from "../../../fusion-charts";
import Widgets from "fusioncharts/fusioncharts.widgets";
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import ReactFusioncharts from "react-fusioncharts";
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import Excel from "fusioncharts/fusioncharts.excelexport";
import Select from 'react-select'
import DropdownIndicator from "../../../render-helpers/dropDownIndicator.js"
import Overlappedbar2d from 'fusioncharts/fusioncharts.overlappedbar2d';

import { sortLogic } from './helpers';

ReactFusioncharts.fcRoot(FusionCharts, Charts, PowerCharts, Widgets, Excel, FusionTheme, Overlappedbar2d);

class BuddyChart extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

      sortBy: "1",
      sorters: {},
      dataSourceState: false
    };

    this.formik = React.createRef();
  }
  searchByFilter = (e) => {
    const { dataSource, attentionData, limit = 5, vertical = false, lumen = false } = this.props;

    const sortBy = e.value;
    let sorted = { property: 'impressions', direction: "ASC" }
    // eslint-disable-next-line
    if (sortBy == 0) {
      sorted = { property: 'impressions', direction: "ASC" }
    }
    // eslint-disable-next-line
    else if (sortBy == 1) {
      sorted = { property: 'impressions', direction: "DESC" }
    }
    if (!lumen) {
      // eslint-disable-next-line
      if (sortBy == 2) {
        sorted = { property: 'attentvScore', direction: "ASC" }
      }
      // eslint-disable-next-line
      else if (sortBy == 3) {
        sorted = { property: 'attentvScore', direction: "DESC" }
      }
    } else {
      // eslint-disable-next-line
      if (sortBy == 2) {
        sorted = { property: 'apm', direction: "ASC" }
      }
      // eslint-disable-next-line
      else if (sortBy == 3) {
        sorted = { property: 'apm', direction: "DESC" }
      }
    }
    // eslint-disable-next-line  

    let dataSourceState = sortLogic(sorted, attentionData, dataSource && dataSource.chart.caption, limit, vertical, lumen)
    this.setState({ sortBy, sorters: sorted, dataSourceState: dataSourceState });
  }



  rendersortBy = () => {
    const { dataSource, sortData } = this.props;
    const { sortBy } = this.state
    return (
      <div className="d-flex flex-row flex-wrap justify-content-between align-items-center mb-3">
        <label className="bb-body-txt mr-2 mb-0 align-self-center pt-0 mb-1 bb-label-color">Sort {dataSource.chart.caption} by</label>
        <Select
          options={sortData}
          value={sortBy ? sortData[sortBy] : ""}
          onChange={this.searchByFilter}
          placeholder="Choose..."
          classNamePrefix="max-width bb-select-light"
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPlacement="auto"
          components={{ DropdownIndicator }}
        />
      </div>
    )
  }
  render() {
    const { dataSourceState } = this.state
    return (
      <>
        {this.props.hasSorting ? this.rendersortBy() : null}

        <ReactFusioncharts
          type={this.props.type}
          width={this.props.width || "100%"}
          height={this.props.height || "100%"}
          dataFormat="JSON"
          dataSource={dataSourceState || this.props.dataSource}
        />
      </>
    );
  }
}
export default BuddyChart;