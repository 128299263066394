
import { addDays } from 'date-fns';
import { formatDateDisplay } from '../../../utilities/customFunctions'

const OrderAxis = (data, seriesname, titlepos = "left", numberprefix = "", numdivlines = "", divlineisdashed = "1", maxvalue = "", numbersuffix = "") => {
    return (
        {
            title: seriesname,
            titlepos: titlepos,
            axisonleft: titlepos === "left" ? "1" : "0",
            numberprefix: numberprefix,
            numdivlines: numdivlines,
            divlineisdashed: divlineisdashed,
            maxvalue: maxvalue,
            numbersuffix: numbersuffix,
            dataset: [
                {
                    seriesname: seriesname,
                    linethickness: "3",
                    data: [
                        ...data
                    ]
                }
            ]
        })
}


export const createAbsoluteDataSet = (categories, impression, dailyViewableImp, dailyEngagement, dailyClicks) => {
    return (
        {
            chart: {
                caption: "Total Numbers",
                subcaption: "For the selected time range",
                showvalues: "0",
                labeldisplay: "ROTATE",
                rotatelabels: "1",
                plothighlighteffect: "fadeout",
                plottooltext: "$seriesName in $label : <b>$dataValue</b>",
                theme: "buddy-theme",
                exportEnabled: "1",
                exportFormats: "png | jpg | svg | csv| xlsx",
                exportMode: "client",
                exportFileName: "Daily breakdown (Numbers)"
            },
            axis:
                [
                    { ...OrderAxis(impression, "Impressions", "left") },
                    { ...OrderAxis(dailyViewableImp, "Viewable Impressions", "left") },
                    { ...OrderAxis(dailyEngagement, "Engagements", "RIGHT") },
                    { ...OrderAxis(dailyClicks, "Clicks", "RIGHT") },
                ],
            categories:
                [
                    {
                        category: [
                            ...categories
                        ]
                    }
                ]
        }
    )
}
export const createPercentageDataSet = (categories, dailyViewableRate, dailyEngagementRate, dailyCtr) => {
    return (
        {
            chart: {
                caption: "Percentages",
                subcaption: "For the selected time range",
                showvalues: "0",
                labeldisplay: "ROTATE",
                rotatelabels: "1",
                plothighlighteffect: "fadeout",
                plottooltext: "$seriesName in $label : <b>$dataValue</b>",
                theme: "buddy-theme",
                paletteColors: "#8cca8b, #e06e5e, #fec663,#1272a4, #01baef, #BC95DF, #67CDF2",
                exportEnabled: "1",
                exportFormats: "png | jpg | svg | csv| xlsx",
                exportMode: "client",
                exportFileName: "Daily breakdown (Percentages)"

            },
            axis:
                [
                    { ...OrderAxis(dailyViewableRate, "Viewability %", "left", "", "", "1", "", "%") },
                    { ...OrderAxis(dailyEngagementRate, "Engagement Rate %", "left", "", "", "1", "", "%") },
                    { ...OrderAxis(dailyCtr, "CTR %", "RIGHT", "", "", "1", "", "%") },
                ],
            categories:
                [
                    {
                        category: [
                            ...categories
                        ]
                    }
                ]
        }
    )
}


export const createAbsoluteDataSetAttention = (categories, impression, scores) => {
    return (
        {
            chart: {
                caption: "Daily breakdown",
                subcaption: "For the selected time range",
                showvalues: "0",
                labeldisplay: "ROTATE",
                rotatelabels: "1",
                plothighlighteffect: "fadeout",
                plottooltext: "$seriesName in $label : <b>$dataValue</b>",
                theme: "buddy-theme",
                exportEnabled: "1",
                exportFormats: "png | jpg | svg | csv| xlsx",
                exportMode: "client",
                exportFileName: "Daily breakdown (Numbers)",
                paletteColors: "#89BCD8, #A01A7D !important"
            },
            axis:
                [
                    { ...OrderAxis(impression, "Impressions", "left") },
                    { ...OrderAxis(scores, "Attention Time (APM)", "RIGHT") },
                   
                ],
            categories:
                [
                    {
                        category: [
                            ...categories
                        ]
                    }
                ]
        }
    )
}

export const getCategoriesDates = (startDate, stopDate) => {
    var dateArray = [];
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push({ label: formatDateDisplay(currentDate) });
        currentDate = addDays(currentDate, 1)
    }
    return dateArray;
}


export const createSpatklineDataset = (dataStructure, caption = "", numberprefix = "", numbersuffix = "") => {
    return (
        {
            chart: {
                caption: "",
                subcaption: "",
                numberprefix: numberprefix,
                charttopmargin: "10",
                numbersuffix: numbersuffix,
                theme: "buddy-theme",
                showclosevalue: "",
                showopenvalue: "",
                setadaptiveymin: "",
            },
            dataset:
                [
                    {
                        data: [
                            ...dataStructure
                        ]
                    }
                ],

        }
    )
}



export const createBarDataset = (dataStructure, caption = "") => {
    return (
        {
            chart: {
                caption: caption,
                theme: "buddy-theme",
                subcaption: "For the selected time range",
                plottooltext:
                    "$label: <b>$dataValue</b>",
                showvalues: "0",
                decimals: "1",
                yAxisMaxValue: 100,
                numbersuffix: "%",
                usesameslantangle: "0",
                showpercentvalues: "1",
                exportEnabled: "1",
                exportFormats: "png | jpg | svg | csv| xlsx",
                exportMode: "client",
                exportFileName: "Video breakdown"
            },
            data: [
                ...dataStructure
            ]

        }
    )
}

export const create2DBarDataset = (dataStructure = [], caption = "Publisher breakdown") => {
    return (
        {
            chart: {
                caption: caption,
                theme: "buddy-theme-1",
                //subcaption: "Top 10 publisher breakdown",
                plottooltext:
                    "$label: <b>$dataValue</b>",
                showvalues: "0",
                decimals: "0",
                numbersuffix: "",
                usesameslantangle: "0",
                showpercentvalues: "1",
                labelPadding: "10",
                yAxisMaxValue: 1000,
                exportEnabled: "0",
                exportFormats: "png | jpg | svg | csv| xlsx",
                exportMode: "client",
                exportFileName: "Publisher breakdown"
            },
            data: [
                ...dataStructure
            ]

        }
    )
}


export const createLedDataset = (dataStructure, caption = "") => {
    return (
        {
            chart: {
                caption: caption,
                theme: "buddy-theme",
                subcaption: "",
                lowerLimit: "0",
                upperLimit: "1000",
                valueAbovePointer: "1",
                lowerLimitDisplay: "0",
                upperLimitDisplay: "1000",
                numberSuffix: "",
                exportEnabled: "1",
                exportFormats: "png | jpg | svg | csv| xlsx",
                exportMode: "client",
                exportFileName: caption,
                gaugeFillMix: "{light}"

            },
            colorRange: {
                "color": [
                    {
                        minValue: "0",
                        maxValue: dataStructure,
                        code: "#A01A7D"
                    },
                    {
                        minValue: dataStructure,
                        maxValue: "1000",
                        code: "#F2F2F2"
                    }
                ]
            },
            pointers: {
                pointer: [
                    {
                        value: dataStructure
                    }
                ]
            }


        }
    )
}



export const createOverlappingDataset = (dataStructure, caption = "") => {
    return (
        {
            chart: {
                caption: caption,
                theme: "buddy-theme",
                subcaption: "Against benchmark",
                lowerLimit: "0",
                upperLimit: "1000",
                plottooltext:
                "<b>$seriesname - $value</b>",
                valueAbovePointer: "1",
                lowerLimitDisplay: "0",
                upperLimitDisplay: "1000",
                numberSuffix: "",
                exportEnabled: "1",
                exportFormats: "png | jpg | svg | csv| xlsx",
                exportMode: "client",
                exportFileName: caption,
                gaugeFillMix: "{light}"

            },
            categories: [
                {
                    "category": [
                        {
                            "label": ""
                        }

                    ]
                }
            ],
            dataset: [
                {
                    "seriesname": "Benchmark",
                    "data": [
                        {
                            "value": 385
                        }

                    ]
                },
                {
                    "seriesname": "Attention Time (APM)",
                    "data": [
                        {
                            "value": dataStructure
                        }
                    ]
                }
            ]


        }
    )
}


const addonDataSetLumen = (data, caption, max = 5, isVertical = false, key = "") => {
    let labels = []
    let dataSeriesImp = []
    let dataSeriesScore = []
    for (let i = 0; i < data.length && i < max; i++) {
        let obj = data[i]
        if (obj && key) {
            labels[i] = { "label": obj[key] }
        }
        dataSeriesImp[i] = { "value": obj.impressions }
        dataSeriesScore[i] = { "value": obj.apm || 0 }
    }
    return createMyltiSeriesDataset(labels, dataSeriesImp, dataSeriesScore, caption, isVertical, "Attention Time (APM)")
}


const addonDataSet = (data, caption, max = 5, isVertical = false) => {
    let labels = []
    let dataSeriesImp = []
    let dataSeriesScore = []
    for (let i = 0; i < data.length && i < max; i++) {
        let obj = data[i]
        let metrics = (obj && obj.metrics) || false
        if (obj && obj.key) {
            labels[i] = { "label": obj.key }
        }
        if (metrics) {
            dataSeriesImp[i] = { "value": metrics.impressions }
            dataSeriesScore[i] = { "value": metrics.attentvScore }
        }
    }
    return createMyltiSeriesDataset(labels, dataSeriesImp, dataSeriesScore, caption, isVertical)
}
export const getAddonDatas = (data, lumen = false) => {
    let AddonData = {};
    if (lumen) {
        AddonData = {
            "publisher": addonDataSetLumen(data.publishers || false, "Publisher Breakdown", 10, true, "domain"),
            "deviceType": addonDataSetLumen(data.device_type || false, "Device Type", 5, true, "device_type"),
        }
    }
    else {
        AddonData = {
            "publisher": addonDataSet(data.publisher || false, "Publisher Breakdown", 10, true),
            "deviceType": addonDataSet(data.deviceType || false, "Device Type"),
            "deviceModel": addonDataSet(data.deviceModel || false, "Device Model"),
            "operatingSystem": addonDataSet(data.operatingSystem || false, "Operating System"),
            "browser": addonDataSet(data.browser || false, "Browser")
        }
    }
    return AddonData

}



export const sortLogic = (sort, originalData, caption = "", limit = 5, vertical = false, lumen = false) => {

    if (lumen) {
        let key = originalData?.length && Object.keys(originalData[0])[0]
        // eslint-disable-next-line
        let data = originalData.sort((a, b) => {
            if (a && b) {
                let sortBy = sort.property
                if (sort.direction === "DESC") {
                    return b[sortBy] - a[sortBy];
                } else {
                    return a[sortBy] - b[sortBy];
                }
            }
        });
        return addonDataSetLumen(data, caption, limit, vertical, key)

    }
    else {
        // eslint-disable-next-line
        let data = originalData.sort((a, b) => {
            if (a && b && a.metrics && b.metrics) {
                let sortBy = sort.property
                if (sort.direction === "DESC") {
                    return b.metrics[sortBy] - a.metrics[sortBy];
                } else {
                    return a.metrics[sortBy] - b.metrics[sortBy];
                }
            }
        });
        return addonDataSet(data, caption, limit, vertical)
    }


}


const createMyltiSeriesDataset = (dataLabel, dataSeriesImp, dataSeriesScore, caption = "", vertical = false, sYAxisName = "Attention Score") => {
    let isVertical = vertical ? { labelDisplay: "rotate", slantLabel: "1" } : {}
    return (
        {
            chart: {
                ...isVertical,
                plottooltext:
                    "$seriesName: <b>$dataValue</b>",
                caption: caption,
                xAxisname: "",
                theme: "buddy-theme",
                pYAxisName: "Impressions",
                sYAxisName: sYAxisName,
                numberPrefix: "",
                sNumberSuffix: "",
                sYAxisMaxValue: "1000",
                exportEnabled: "1",
                exportFormats: "png | jpg | svg | csv| xlsx",
                exportMode: "client",
                exportFileName: caption,

            },
            "categories": [

                {
                    "category": [
                        ...dataLabel
                    ]
                }
            ],
            "dataset": [

                {
                    "seriesName": "Impressions",
                    "data": [
                        ...dataSeriesImp
                    ]
                },
                {
                    "seriesName": sYAxisName,
                    "parentYAxis": "S",
                    "renderAs": "line",
                    "showValues": "0",
                    "data": [
                        ...dataSeriesScore
                    ]
                }
            ]

        }
    )
}
export const createAttentionScoreRM = (value, caption = "") => {

    return (
        {
            chart: {
                caption: caption,
                lowerlimit: "0",
                upperlimit: "1000",
                showvalue: "0",
                numbersuffix: "",
                theme: "buddy-theme",
            },
            colorrange: {
                "color": [
                    {
                        minvalue: "0",
                        maxvalue: value,
                        code: "#A01A7D"
                    },
                    {
                        minvalue: value,
                        maxvalue: 1000,
                        code: "#F2F2F2"
                    }

                ]
            },
            dials: {
                dial: [
                    {
                        showValue: "1",
                        value: value,
                        bgColor: "#F2F2F2",
                        borderAlpha: '100',
                        borderColor: '#F2F2F2',
                        radius: "0",
                        rearExtension: "0",
                        bgHoverColor: "#F2F2F2",
                        bgHoverAlpha: "0",
                        BorderHoverColor: "#F2F2F2",
                        BorderHoverAlpha: "0",
                        BorderHoverThickness: "0",
                        baseRadius: '0',
                        baseWidth: "0"
                    }
                ]
            }


        }
    )
}